<script lang="ts">
import { projectKey } from '~/types/inject'
import type { Project } from '~/types/project'

import { PROJECT_CONTENT_CLASS } from '../sections/ProjectSection.vue'

export const PROJECT_PAGE_CLASS = 'project-page'
</script>

<script setup lang="ts">
const props = defineProps<{
  project: Project
}>()

defineSlots<{
  default: void
  header_text: void
  description: void
}>()

const projectProvide = inject(projectKey)
onMounted(() => {
  if (projectProvide) projectProvide.value = toRef(props, 'project')
})

useSeoMeta({
  title: `${props.project.header.headline.replaceAll(/<.*>/g, '')}: ${
    props.project.header.project
  }`,
  description: props.project.header.info_card.description,
  themeColor: props.project.primary_color,
})
</script>

<template>
  <div
    :class="PROJECT_PAGE_CLASS"
    class="flex flex-col items-center gap-4"
    :style="{
      '--color-primary': project.primary_color,
      '--color-secondary': project.secondary_color,
    }"
  >
    <ProjectHeader :project="project">
      <template #header_text>
        <slot name="header_text" />
      </template>

      <template #description>
        <slot name="description" />
      </template>
    </ProjectHeader>

    <Container class="gap-4" :class="PROJECT_CONTENT_CLASS">
      <slot />
    </Container>
  </div>
</template>
